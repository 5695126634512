import React from "react"

export default function SendIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.9906 13.7253C19.9823 13.6711 19.9698 13.617 19.949 13.567C19.9448 13.5587 19.9448 13.5503 19.9406 13.542L17.0241 8.54225C16.9491 8.41308 16.8116 8.33394 16.6658 8.33394H14.5951C14.3868 8.33394 14.1993 8.48393 14.1701 8.6881C14.1326 8.94641 14.3326 9.16726 14.5826 9.16726H16.4283L18.8574 13.3337H13.7452C13.516 13.3337 13.3285 13.5212 13.3285 13.7504V14.5836C13.3285 15.2753 12.7702 15.8336 12.0786 15.8336H8.32876C7.63711 15.8336 7.07882 15.2753 7.07882 14.5836V13.7504C7.07882 13.5212 6.89133 13.3337 6.66216 13.3337H1.13746L3.56651 9.16718H6.22887C6.43718 9.16718 6.62467 9.01719 6.65384 8.81302C6.69134 8.55471 6.49135 8.33386 6.24137 8.33386H3.32902C3.17903 8.33386 3.04154 8.41304 2.97072 8.54217L0.0541921 13.5419C0.0500126 13.5502 0.0500126 13.5586 0.0458722 13.5669C0.0333729 13.5919 0.025053 13.6169 0.0208735 13.6461C0.0125537 13.6711 0.00837419 13.7003 0.00419473 13.7294C0.00419473 13.7377 1.52588e-05 13.7461 1.52588e-05 13.7544V19.5833C1.52588e-05 19.8125 0.187505 20 0.416673 20H19.5823C19.8115 20 19.999 19.8125 19.999 19.5833V13.7503C19.9948 13.7419 19.9906 13.7336 19.9906 13.7253ZM19.1615 19.1666H0.829151V14.1669H6.24555V14.5836C6.24555 15.7335 7.17882 16.6668 8.32876 16.6668H12.0786C13.2285 16.6668 14.1618 15.7335 14.1618 14.5836V14.1669H19.1615V19.1666Z"
          fill="#3870DE"
        />
        <path
          d="M19.1615 19.1666H0.829151V14.1669H6.24555V14.5836C6.24555 15.7335 7.17882 16.6668 8.32876 16.6668H12.0786C13.2285 16.6668 14.1618 15.7335 14.1618 14.5836V14.1669H19.1615V19.1666Z"
          fill="#3870DE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.28595 4.4379L9.52859 0.195261C9.78894 -0.0650883 10.211 -0.0650883 10.4714 0.195261L14.714 4.4379C14.9744 4.69825 14.9744 5.12036 14.714 5.38071C14.4537 5.64106 14.0316 5.64106 13.7712 5.38071L10.6667 2.27614L10.6667 11.3333C10.6667 11.7015 10.3682 12 9.99999 12C9.6318 12 9.33332 11.7015 9.33332 11.3333L9.33332 2.27614L6.22875 5.38071C5.9684 5.64106 5.54629 5.64106 5.28595 5.38071C5.0256 5.12036 5.0256 4.69825 5.28595 4.4379Z"
          fill="#3870DE"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import React, { useState } from "react"
import Form from "../Form"
import CardForm from "../CardForm"
import FormHeader from "../FormHeader"
import ThankYouMessage from "../ThankYouMessage"
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {sendEmail, sendHubSpotForm} from "../../../js/helper"
import { useFormik } from "formik"
import Cookies from "js-cookie"
import { hubSpotEstimateFormId, hubSpotCookieName } from "../../../js/config"

import "./FormContainer.scss"

export default function FormContainer() {
  const fileInput = React.createRef()

  const [thankYou, setThankYou] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)

  const validate = values => {
    if (isWaiting) {
      return false;
    }

    const errors = {}

    const checkEmail = email => {
      if (!email) {
        errors.email = "Please enter your email"
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = "Please enter correct email"
      }
    }

    const checkNumber = number => {
      const userContact = number.trim()
      if (userContact.length < 2) {
        errors.phone = "Number should contain at least 2 characters"
      } else if (userContact.length > 50) {
        errors.phone = "Number should contain less than 50 characters"
      }
    }

    const checkFullName = name => {
      const userFullName = name.trim()
      if (userFullName.length < 2) {
        errors.name = "Please enter your Full Name"
      } else if (!/^([a-z0-9_-]+\s)*[a-z0-9_-]{1,15}$/i.test(userFullName)) {
        errors.name = "Please enter correct Full Name"
      }
    }

    const checkCompanyName = company => {
      const userCompany = company.trim()
      if (userCompany.length < 2) {
        errors.company = "Please enter correct Company Name"
      }
    }

    // if (!values.email && !values.phone) {
    //   errors.message = "Please enter your email or skype/phone number"
    // } else 
    // if (!values.email && values.phone) {
    //   checkNumber(values.phone)
    // } else if (!values.phone && values.email) {
    //   checkEmail(values.email)
    // } else if (values.email && values.phone) {
    //   checkEmail(values.email)
    //   checkNumber(values.phone)
    // }

    checkFullName(values.name)
    checkCompanyName(values.company)
    if(values.phone) checkNumber(values.phone)
    checkEmail(values.email)
    
    return errors
  }

  const handleFileLoad = event => {
    const { file } = formik.values
    if (!event.target.files[0]) {
      return
    }
    let fileObject = event.target.files[0]
    file.files[0] = fileObject
    formik.validateForm()
  }

  const fullNameChange = event => {
    const { value } = event.target
    if (!/^[a-zа-яё]*(?:\s+[a-zа-яё]*){0,2}$/i.test(value)) {
      return
    }
    formik.handleChange(event)
    formik.validateForm()
  }

  const resetSendingFile = event => {
    const { file } = formik.values
    event.stopPropagation()
    file.files[0] = {}
    fileInput.current.value = ""
    formik.validateForm()
  }

  const uploadClickHandler = event => {
    event.preventDefault()
    fileInput.current.click()
  }

  const setGoal = event => {
    if (!isWaiting) {
      formik.values.goal = event.currentTarget.getAttribute("data-value");
    }
  }

  const setScope = event => {
    if (!isWaiting) {
      const {scope} = formik.values
      let tech = event.currentTarget.getAttribute("data-value")
      if (formik.values.scope.indexOf(tech) >= 0) {
        let deleteIndex = formik.values.scope.indexOf(tech)
        scope.splice(deleteIndex, 1)
      } else {
        scope.push(tech)
      }
    }
  }

  const setBudget = event => {
    if (!isWaiting) {
      formik.values.budget = event.currentTarget.getAttribute("data-value")
    }
  }

  const isBrowser = typeof window !== 'undefined';
  const hutk = isBrowser ? Cookies.get(hubSpotCookieName) : null;
  const pageUri = isBrowser ? window.location.href : null;
  const pageName = isBrowser ? document.title : null;

  const formik = useFormik({
    initialValues: {
      url:"",
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      goal: "",
      scope: [],
      budget: "",
      file: {
        files: [{}],
      },
    },
    validate,
    onSubmit: async (values) => {

      if (isWaiting) {
        return false;
      }

      if(!expanded) {
        formik.values.goal = '';
        formik.values.budget = '';
        formik.values.scope = [];
      }

      setIsWaiting(true);
      values.form = expanded ? 1 : 6;
      values.scope = values.scope.join(" ");
      await sendEmail(
          formik.values,
          file?.files[0] ? formik.values.file : null,
          hubSpotEstimateFormId,
          {
            hutk: hutk,
            pageUri: pageUri,
            pageName: pageName,
          }
      );
      values.scope = [];
      setIsWaiting(false);
      setThankYou(true);
      document.querySelector('#estimate-form-container').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    },
  })

  return (
    <React.Fragment>
      {!thankYou ? <FormHeader /> : null}
      <div className="form-container" id="estimate-form-container">
        {
          !thankYou ? (
            <>
              <Form
                change={formik.handleChange}
                fullNameChange={fullNameChange}
                blur={formik.handleBlur}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                fileUpload={handleFileLoad}
                fileClick={uploadClickHandler}
                reset={resetSendingFile}
                fileRef={fileInput}
                isWaiting={isWaiting}
                setFieldValue={formik.setFieldValue}
              />
              <CardForm
                goal={setGoal}
                scope={setScope}
                budget={setBudget}
                values={formik.values}
                errors={formik.errors}
                submit={formik.handleSubmit}
                expanded={expanded}
                setExpanded={setExpanded}
                isWaiting={isWaiting}
              />
            </>
            ) : <ThankYouMessage />
        }
      </div>
    </React.Fragment>
  )
}

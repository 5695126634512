import React from "react"

import {
  AdminIcon,
  AndroidIcon,
  BackendIcon,
  IosIcon,
  CloudIcon,
  WebIcon,
  PeopleIcon,
  MagnifyIcon,
  LightbulbIcon,
} from "../../images/Estimate/Icons"

const optionList = {
  goals: {
    number: "1.",
    header: "What would you like to do",
    cardClass: "goal",
    data: [
      {
        id: "1",
        img: <LightbulbIcon />,
        description: "Build Something New",
      },
      {
        id: "2",
        img: <MagnifyIcon />,
        description: "Improve Exiting Projects",
      },
      {
        id: "3",
        img: <AdminIcon />,
        description: "Extend My Team",
      },
    ],
  },
  scope: {
    number: "2.",
    header: "What is the scope of your project?",
    cardClass: "scope",
    data: [
      {
        id: "1",
        img: <IosIcon />,
        description: "iOS",
      },
      {
        id: "2",
        img: <AndroidIcon />,
        description: "Android",
      },
      {
        id: "3",
        img: <CloudIcon />,
        description: "Cloud",
      },
      {
        id: "4",
        img: <WebIcon />,
        description: "Web",
      },
      {
        id: "5",
        img: <BackendIcon />,
        description: "Back-End",
      },
      {
        id: "6",
        img: <PeopleIcon />,
        description: "Admin Panel",
      },
    ],
  },
  budged: {
    number: "3.",
    header: "What is the budget of your project?",
    cardClass: "price",
    data: [
      {
        id: "1",
        description: "Under $10000",
      },
      {
        id: "2",
        description: "$10000-$20000",
      },
      {
        id: "3",
        description: "$20000-$50000",
      },
      {
        id: "4",
        description: "$50000-$100000",
      },
      {
        id: "5",
        description: "$100000-$150000",
      },
      {
        id: "6",
        description: "Over $150000",
      },
    ],
  },
}

export default optionList

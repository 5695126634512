import React from 'react';

import './FormHeader.scss';

export default function FormHeader() {
  return (
    <div className='form-header-container' id='estimate-header'>
      <h2 className='form-header'>
        Our experienced development team is always on-hand to advise you on the
        best approach and provide with a high-quality estimation.
      </h2>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import SendBtnIcon from "../../../images/Estimate/Icons/sendBtnSvg"
import resetImg from "../../../images/cancel.svg"

import "./Form.scss"

export default function Form({
  change,
  blur,
  values,
  errors,
  touched,
  fileUpload,
  fileClick,
  reset,
  fileRef,
  setFieldValue,
  fullNameChange,
  isWaiting,
}) {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  const [isWrongSize, setWrongSize] = useState(false)
  const [isWrongType, setWrongType] = useState(false);

  const onInputChange = event => {
    let fileOk = true;
    const fileType = event.target.files?.[0]?.type;
    const acceptedTypesOfFiles = ["application/pdf", //PDF
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //DOCX
                                  "application/msword", // DOC
                                  "text/richtext", // RTF
                                  "application/rtf", // RTF
                                  "text/plain", // TXT
                                  "application/vnd.oasis.opendocument.text" // ODT
                                ];

    if (event.target.files?.[0]?.size > 10000000) {
      setWrongSize(true);
      fileOk = false;
    } else {
      setWrongSize(false);
    }

    if (!acceptedTypesOfFiles.includes(fileType)){
      setWrongType(true);
      fileOk=false;
    } else {
      setWrongType(false);
    }

    if (!fileOk) {
      reset(event);
    } else {
      fileUpload(event);
    }

  }

  const fileName = values.file.files[0].name
  useEffect(() => {
    setFieldValue("url", sendUrl)
  }, [])

  let uploadButtonSign = "Attach files"
  if (fileName && fileName.length <= 10) {
    uploadButtonSign = fileName
  } else if (fileName && fileName.length > 10) {
    uploadButtonSign = fileName.substring(0, 10) + "..."
  }

  return (
    <div className="form-wrapper">
      <form onSubmit={() => SubmitHandler(event, values, errors)} id="form">
        <div className="input-row">
          <div
            className={
              errors.name && touched.name
                ? "input-holder error"
                : "input-holder"
            }
          >
            <input
              id="name"
              name="name"
              className={values.name ? "form-name active" : "form-name"}
              disabled={isWaiting}
              type="text"
              maxLength="30"
              onBlur={blur}
              onChange={fullNameChange}
              value={values.name}
            />
            <label
              htmlFor="name"
              className={values.name ? "name-label active" : "name-label"}
            >
              Full Name*
            </label>
            {errors.name && touched.name ? (
              <div className="error-message">{errors.name}</div>
            ) : null}
          </div>

            <div
              className={
                errors.company && touched.company
                    ? "input-holder error"
                    : "input-holder"
              }
            >

            <input
              id="company"
              name="company"
              className={
                values.company ? "form-company active" : "form-company"
              }
              disabled={isWaiting}
              maxLength="120"
              type="text"
              onBlur={blur}
              onChange={change}
              value={values.company}
            />
            <label
              htmlFor="company"
              className={
                values.company ? "company-label active" : "company-label"
              }
            >
              Company Name*
            </label>
            {errors.company && touched.company ? (
              <div className="error-message">{errors.company}</div>
            ) : null}
          </div>
        </div>

        <div
          className={
            errors.message && (touched.phone || touched.email)
              ? "input-row message-error"
              : "input-row"
          }
        >
          <div
            className={
              errors.email && touched.email
                ? "input-holder error"
                : "input-holder"
            }
          >
            <input
              id="email"
              name="email"
              className={values.email ? "form-email active" : "form-email"}
              maxLength="30"
              type="email"
              disabled={isWaiting}
              onBlur={blur}
              onChange={change}
              value={values.email}
            />
            <label
              htmlFor="email"
              className={values.email ? "email-label active" : "email-label"}
            >
              Email*
            </label>
            {errors.email && touched.email ? (
              <div className="error-message">{errors.email}</div>
            ) : null}
          </div>

          <div
            className={
              errors.phone && touched.phone
                ? "input-holder error"
                : "input-holder"
            }
          >
            <input
              id="number"
              name="phone"
              className={values.phone ? "form-number active" : "form-number"}
              maxLength="50"
              type="text"
              disabled={isWaiting}
              onBlur={blur}
              onChange={change}
              value={values.phone}
            />
            <label
              htmlFor="number"
              className={values.phone ? "number-label active" : "number-label"}
            >
              Skype/Phone number
            </label>
            {errors.phone && touched.phone ? (
              <div className="error-message">{errors.phone}</div>
            ) : null}
          </div>
          {errors.message && (touched.phone || touched.email) ? (
            <div className="error-banner">{errors.message}</div>
          ) : null}
        </div>

        <div className="input-row">
          <div className="textarea-holder">
            <textarea
              id="message"
              name="message"
              className={
                values.message ? "form-message active" : "form-message"
              }
              disabled={isWaiting}
              maxLength="2000"
              onChange={change}
              value={values.message}
            />
            <label
              htmlFor="message"
              className={
                values.message ? "message-label active" : "message-label"
              }
            >
              How can we help?
            </label>
          </div>
        </div>

        <div className="send-file-container">
          <div
            className={isWaiting ? "send-file-btn disabled" : (fileName ? "send-file-btn sent" : "send-file-btn")}
            onClick={fileClick}
          >
            <span className="send-file-btn-sign">{uploadButtonSign}</span>
            {fileName ? (
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                onClick={reset}
                src={resetImg}
              />
            ) : (
              <SendBtnIcon />
            )}
          </div>

          <div className="valid-formats">
            <span 
              className={`valid-formats-header ${isWrongType && "wrong_type"}`}
            >
              Valid formats:{" "}
              <span className={`valid-formats-description ${
                  isWrongType && "wrong_type"
                }`}
                >
                .pdf, .docx, .doc, .rtf, .txt, .odt{" "}
                {isWrongType && (
                  <span className="wrong_type">(file NOT attached)</span>
                )}
              </span>
            </span>

            <span
              className={`valid-formats-header ${isWrongSize && "wrong_size"}`}
            >
              Max size:{" "}
              <span
                className={`valid-formats-description ${
                  isWrongSize && "wrong_size"
                }`}
              >
                10 mb{" "}
                {isWrongSize && (
                  <span className="wrong_size">(file NOT attached)</span>
                )}
              </span>
            </span>
          </div>

          <input
            id="file"
            name="file"
            className="file"
            ref={fileRef}
            type="file"
            disabled={isWaiting}
            accept=".pdf, .docx, .doc, .rtf, .txt, .odt"
            onChange={onInputChange}
          />
        </div>
      </form>
    </div>
  )
}

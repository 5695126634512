import React from "react"

import "./Banner.scss"

export default function Banner() {
  return (
    <div className="banner-container" id="banner">
      <div className="banner-wrapper">
        <div className="banner-labels" id="labels">
          <h3 className="banner-supheader">Let’s work with us!</h3>
          <h1 className="banner-header">Estimate project</h1>
          <h3 className="banner-subheader">
            The information provided will be processed and we will contact you
          </h3>
        </div>
      </div>
    </div>
  )
}

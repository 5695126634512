import React, { useContext } from 'react';

import CardSelector from '../CardSelector';

import optionList from '../../../information/Estimate/dataForOptions';

import { scrollToInvalidField } from '../../../js/helper';

import CheckMark from '../../../images/Estimate/Icons/checkmark.svg';
import './CardForm.scss';
import SnackbarContext from '../../../contexts/SnackbarContext';

export default function CardForm({
  goal,
  scope,
  budget,
  values,
  errors,
  submit,
  expanded,
  setExpanded,
  isWaiting,
}) {
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  const context = useContext(SnackbarContext);

  const SubmitHandler = (event, inputValues, errorValues) => {
    event.preventDefault();
    scrollToInvalidField(inputValues, errorValues);

    const snackbarData = {
      message: 'No Internet Connection. Check your network and try again.',
      severity: 'warning',
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
    }

    if (!window.navigator.onLine) return context?.handleOpenSnackbar({ ...snackbarData })

    submit();
  }

  return (
      <div className="estimation-container">
        <div className="root">
          <div className="summary">
            <div className="checkbox-container">
              <label className="checkbox-label">
                {expanded && (
                    <img src={CheckMark} className="checkbox-image" />
                )}
                <input
                    className="checkbox-input"
                    type="checkbox"
                    name="details"
                    disabled={isWaiting}
                    onClick={() => setExpanded(!expanded)}
                />
                I would like to provide more details about the project
              </label>
            </div>
          </div>
          <div className={`details${!expanded ? "" : " open"}`}>
            <CardSelector
                options={optionList.goals}
                handleClick={goal}
                disabled={isWaiting}
            />
            <CardSelector
                options={optionList.scope}
                handleClick={scope}
                multiple
                disabled={isWaiting}
            />
            <CardSelector
                options={optionList.budged}
                handleClick={budget}
                disabled={isWaiting}
            />
          </div>
        </div>
        <button
            type="button"
            disabled={isWaiting}
            className="submit-btn"
            onClick={event => SubmitHandler(event, values, errors)}
        >
          {isWaiting ? 'Sending...' : 'Estimate project'}
        </button>
      </div>
  )
}

import React from "react"
import "./CardSelector.scss"

export default class CardSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: "",
    }
  }

  toggleClick = event => {
    const { handleClick, multiple, disabled } = this.props
    if (disabled) {
      return false;
    }
    if (multiple) {
      event.currentTarget.classList.toggle("active")
    } else {
      this.setState({
        active: event.currentTarget.getAttribute("data-value"),
      })
    }
    handleClick(event)
  }

  render() {
    const { options, disabled } = this.props

    return (
      <div className="options-container">
        <h4 className="options-header">
          <span className="options-number">{options.number}</span>
          {options.header}
        </h4>
        <div className="options-cards-container">
          {options.data.map(item => {
            const { id, img, description } = item
            const { cardClass } = options
            return (
              <div
                key={id}
                className={
                  this.state.active === description
                    ? `options-card ${disabled ? 'disabled' : ''} ${cardClass} active`
                    : `options-card ${disabled ? 'disabled' : ''} ${cardClass}`
                }
                data-value={description}
                onClick={this.toggleClick}
              >
                {item.img ? img : null}
                <div className="options-description">{description}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

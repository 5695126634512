import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import Banner from "../components/Estimate/Banner"
import FormContainer from "../components/Estimate/FormContainer"
import { mainUrl } from "../js/config"

export default class EstimatePage extends React.Component {
  constructor(props) {
    super(props)
    this.scrollOnLoad = this.scrollOnLoad.bind(this)
  }

  scrollOnLoad = () => {
    const node = document.getElementById("name")
    node.scrollIntoView({ block: "center", behavior: "smooth" })
  }

  componentDidMount() {
    this.scrollOnLoad()
  }

  render() {
    return (
      <div>
        <SEO
          title="Estimate Your Web or Software Development Project — OS-System"
          description="Estimate your project with OS-System.Whether you are planning to build an innovative web or software development product, we can help you digitally transform your businesses."
          canonical={`${mainUrl}/estimate/`}
        />
        <Layout showFormPoint={100}>
          <Banner />
          <FormContainer />
        </Layout>
      </div>
    )
  }
}
